<template>
  <div class="price">
    <div class="price-title">{{ 'price' }}:</div>
    <div class="price-range">
      <div class="price-range-min">
        <InputElement
          :value="prices.min"
          placeholder="min"
          type="number"
          @input="updateInput($event, 'min')"
        />
      </div>
      <span>-</span>
      <div class="price-range-max">
        <InputElement
          :value="prices.max"
          placeholder="max"
          type="number"
          @input="updateInput($event, 'max')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputElement from '../FormControl/InputElement';
import EventBus from '../../utils/EventBus';

export default {
  name: 'Price',
  data() {
    return {
      prices: {
        min: null,
        max: null,
      },
    };
  },
  components: { InputElement },
  methods: {
    updateInput(event, name) {
      this.$set(this.prices, name, event);
      this.$emit('activePrice', this.prices);
    },
  },
  mounted() {
    EventBus.$on('resetPrice', () => {
      this.$set(this.prices, 'min', null);
      this.$set(this.prices, 'max', null);
    });
  },
};
</script>

<style lang="scss">
.price {
  .price-title {
    padding-right: 9px;
  }
  .price-range {
    > div {
      height: 44px;
      width: 52px;
    }
    > span {
      margin: 0 5px;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    &::placeholder {
      color: #2b2b2b;
    }
  }
}
.price,
.price-range {
  align-items: center;
  display: flex;
}
</style>
