<template>
  <div class="filter-range">
    <VueRangeSlider
      ref="slider"
      v-model="value"
      :max="maxDistance"
      :width="`${190 - 52}px`"
      :bg-style="bgStyle"
      :slider-style="processStyle"
      :process-style="processStyle"
      :tooltip-style="tooltipStyle"
    />
  </div>
</template>

<script>
import 'vue-range-component/dist/vue-range-slider.css';
import VueRangeSlider from 'vue-range-component';

export default {
  name: 'Range',
  props: {
    rangeValue: {
      type: Number,
      required: true,
    },
    maxDistance: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      bgStyle: {
        backgroundColor: '#F4F4F4',
      },
      processStyle: {
        backgroundColor: '#E58413',
      },
      tooltipStyle: {
        display: 'none',
      },
    };
  },
  computed: {
    value: {
      get() {
        console.log('get', this.rangeValue);
        return this.rangeValue;
      },
      set(value) {
        console.log('set', value);
        this.$emit('updateRangeValue', value);
      },
    },
  },
  created() {
    VueRangeSlider.methods.handleKeyup = () => console.log;
    VueRangeSlider.methods.handleKeydown = () => console.log;
  },
  components: {
    VueRangeSlider,
  },
};
</script>

<style lang="scss" scoped></style>
