var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.products)?_c('div',{staticClass:"products-categories-category"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('enduser.products.shopCatalog'))+" ")])]),(_vm.products.length)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"filters"},[_c('div',{staticClass:"selection-wrapper"},[_c('SelectElement',{attrs:{"value":_vm.optionsSubCategory,"options":_vm.optionsSubCategories,"multi-select":true,"placeholder-with-selection-length":true,"placeholder":_vm.$t('enduser.products.subcategories')},on:{"input":_vm.inputChange,"dropdown-closed":_vm.handleOnClose}})],1),_c('div',{staticClass:"others-filters"},[_c('div',{staticClass:"range-wrapper"},[_c('div',{staticClass:"range-title"},[_vm._v(" "+_vm._s(_vm.$t('enduser.products.distance'))+": ")]),_c('Range',{attrs:{"range-value":_vm.rangeValue,"max-distance":_vm.maxDistance},on:{"updateRangeValue":_vm.updateRangeValue}}),_c('div',{staticClass:"range-value"},[_c('span',[_vm._v(_vm._s(_vm.rangeValue))]),_c('span',[_vm._v("km")])])],1),_c('div',{staticClass:"rating"},[_c('Rating',{attrs:{"picked":_vm.rating},on:{"starRatingValue":_vm.updateRating}})],1),_c('div',{staticClass:"price"},[_c('Price',{on:{"activePrice":function($event){return _vm.setActiveFilter('price', $event)}}})],1)])]),_c('TableDiv',{attrs:{"data":_vm.getFilteredProducts,"column-width":_vm.tableSizing,"ignore-data":['id', 'distanceWithoutKM', 'subcategories']},on:{"row-click":function($event){return _vm.handleRowClick($event)}},scopedSlots:_vm._u([{key:"header-logo",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.logo'))+" ")]}},{key:"header-company",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.companyName'))+" ")]}},{key:"header-popular_services",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.products.popularServices'))+" ")]}},{key:"header-rating",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.rating'))+" ")]}},{key:"header-price_rating",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.priceRating'))+" ")]}},{key:"header-distance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.distance'))+" ")]}},{key:"cell-logo",fn:function(ref){
var value = ref.value;
return [_c('img',{staticClass:"rounded",staticStyle:{"width":"50px","height":"50px","object-fit":"cover"},attrs:{"src":value || 'https://via.placeholder.com/50'}})]}},{key:"cell-company",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"link"},[_vm._v(" "+_vm._s(value.companyName)),_c('br'),_c('small',[_vm._v(_vm._s(value.description))])])]}},{key:"cell-popularServices",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"txt-orange"},[_vm._v(_vm._s(value))])]}},{key:"cell-rating",fn:function(ref){
var value = ref.value;
return [_c('StarRating',{attrs:{"stars":5,"active-stars":value}})]}},{key:"cell-priceRating",fn:function(ref){
var value = ref.value;
return [_c('StarRating',{attrs:{"stars":5,"active-stars":value}})]}}],null,false,589704733)})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }