<template>
  <div class="rating">
    <div class="rating-title">{{ 'Rating' }}:</div>
    <StarRating
      :stars="stars"
      :active-stars="picked"
      :emit-value="true"
      @starRatingValue="$emit('starRatingValue', $event)"
    />
  </div>
</template>

<script>
import StarRating from '../Rating/StarRating';
export default {
  name: 'Rating',
  components: { StarRating },
  props: {
    picked: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stars: 5,
    };
  },
};
</script>

<style lang="scss" scoped>
.rating {
  align-items: center;
  display: flex;
  .rating-title {
    padding-right: 19px;
  }
}
</style>
