<template>
  <div class="products-categories-category" v-if="products">
    <div class="header">
      <div class="title">
        {{ $t('enduser.products.shopCatalog') }}
      </div>
    </div>
    <div class="content" v-if="products.length">
      <div class="filters">
        <div class="selection-wrapper">
          <SelectElement
            :value="optionsSubCategory"
            @input="inputChange"
            :options="optionsSubCategories"
            :multi-select="true"
            :placeholder-with-selection-length="true"
            :placeholder="$t('enduser.products.subcategories')"
            @dropdown-closed="handleOnClose"
          />
        </div>
        <div class="others-filters">
          <div class="range-wrapper">
            <div class="range-title">
              {{ $t('enduser.products.distance') }}:
            </div>
            <Range
              :range-value="rangeValue"
              :max-distance="maxDistance"
              @updateRangeValue="updateRangeValue"
            />
            <div class="range-value">
              <span>{{ rangeValue }}</span>
              <span>km</span>
            </div>
          </div>
          <div class="rating">
            <Rating :picked="rating" @starRatingValue="updateRating" />
          </div>
          <div class="price">
            <Price @activePrice="setActiveFilter('price', $event)" />
          </div>
        </div>
      </div>
      <TableDiv
        :data="getFilteredProducts"
        :column-width="tableSizing"
        @row-click="handleRowClick($event)"
        :ignore-data="['id', 'distanceWithoutKM', 'subcategories']"
      >
        <template #header-logo="{ value }">
          {{ $t('enduser.services.logo') }}
        </template>
        <template #header-company="{ value }">
          {{ $t('enduser.services.companyName') }}
        </template>
        <template #header-popular_services="{ value }">
          {{ $t('enduser.products.popularServices') }}
        </template>
        <template #header-rating="{ value }">
          {{ $t('enduser.services.rating') }}
        </template>
        <template #header-price_rating="{ value }">
          {{ $t('enduser.services.priceRating') }}
        </template>
        <template #header-distance="{ value }">
          {{ $t('enduser.services.distance') }}
        </template>
        <template #cell-logo="{ value }">
          <img
            class="rounded"
            :src="value || 'https://via.placeholder.com/50'"
            style="width: 50px; height: 50px; object-fit: cover"
          />
        </template>
        <template #cell-company="{ value }">
          <div class="link">
            {{ value.companyName }}<br />
            <small>{{ value.description }}</small>
          </div>
        </template>
        <template #cell-popularServices="{ value }">
          <span class="txt-orange">{{ value }}</span>
        </template>
        <template #cell-rating="{ value }">
          <StarRating :stars="5" :active-stars="value" />
        </template>
        <template #cell-priceRating="{ value }">
          <StarRating :stars="5" :active-stars="value" />
        </template>
      </TableDiv>
    </div>
  </div>
</template>

<script>
import { forEach, orderBy, filter, maxBy, map } from 'lodash';
import TableDiv from '@/components/Table/TableDiv';
import StarRating from '@/components/Rating/StarRating';
import Range from '../../components/Filters/Range';
import Rating from '../../components/Filters/Rating';
import SelectElement from '../../components/FormControl/SelectElement/SelectElement';
import Price from '../../components/Filters/Price';
import EventBus from '../../utils/EventBus';

export default {
  name: 'ProductsCategoriesCategory',

  components: {
    Price,
    Rating,
    Range,
    TableDiv,
    StarRating,
    SelectElement,
  },
  data() {
    return {
      products: null,
      tableSizing: [
        { key: 'logo', width: '83px', fixed: true },
        { key: 'company', width: '250px', fixed: false },
        { key: 'popular_services', width: '100px', fixed: false },
        { key: 'rating', width: '100px', fixed: false },
        { key: 'price', width: '100px', fixed: false },
        { key: 'distance', width: '100px', fixed: true },
        { key: 'address', width: '250px', fixed: false },
      ],
      placeholder: 'subCategory',
      optionsSubCategory: [],
      optionsSubCategories: null,
      maxDistance: null,
      rangeValue: 0,
      rating: 0,
      activeFilter: null,
    };
  },
  computed: {
    getFilteredProducts() {
      let products;
      const subProducts = this.subcategoriesProducts();
      if (!this.activeFilter) {
        products = subProducts;
      } else {
        switch (this.activeFilter.name) {
          case 'rating':
            products = filter(
              subProducts,
              item => item.rating <= this.activeFilter.value
            );

            EventBus.$emit('resetPrice');
            this.resetData('rangeValue', 0);
            break;
          case 'range':
            products = filter(
              subProducts,
              item =>
                !this.activeFilter.value ||
                item.distanceWithoutKM <= this.activeFilter.value
            );
            EventBus.$emit('resetPrice');
            this.resetData('rating', 0);
            break;
          case 'price':
            products = filter(
              subProducts,
              item =>
                !+this.activeFilter.value.min ||
                !+this.activeFilter.value.max ||
                +this.activeFilter.value.min > +this.activeFilter.value.max ||
                (item.price >= +this.activeFilter.value.min &&
                  item.price <= +this.activeFilter.value.max)
            );
            this.resetData('rangeValue', 0);
            this.resetData('rating', 0);
            break;
          // no default
        }
      }
      return products;
    },
  },
  methods: {
    handleRowClick(company) {
      this.$router.push({
        name: 'ProductsCompany',
        params: { id: company.id },
      });
    },
    inputChange(event) {
      console.log('inputChanges', event, this.optionsSubCategory);
      this.optionsSubCategory = event;
    },
    handleOnClose(event) {
      console.log('handleOnClose', event);
    },
    updateRangeValue(event) {
      console.log('updateRangeValue', event);
      this.rangeValue = event;
      this.setActiveFilter('range', event);
    },
    updateRating(event) {
      this.rating = event;
      this.setActiveFilter('rating', event);
    },
    setActiveFilter(name, value) {
      this.activeFilter = {
        name,
        value,
      };
    },
    resetActiveFilter() {
      this.activeFilter = null;
    },
    createProductsData(data) {
      const products = [];
      forEach(data, item => {
        const product = {
          id: item.id,
          logo: item.companyInfo.image || '-',
          company: {
            companyName: item.companyName || '-',
            description: item.description || '-',
          },
          popular_services: item.popularProduct.name || '-',
          rating: +item.rating || 0,
          price: +item.companyInfo.price || '-',
          distance: item.companyInfo.distance || '-',
          subcategories: item.subcategories,
          address: item.companyInfo.address || '-',
          distanceWithoutKM: parseFloat(item.companyInfo.distance),
        };
        products.push(product);
      });
      return orderBy(products, 'rating', ['desc']);
    },
    parseSubcategories(companies) {
      const subcategories = {
        test: {
          value: 13,
          label: 'test',
        },
      };
      forEach(companies, company => {
        forEach(company.subcategories, sub => {
          if (sub && !subcategories[sub.name]) {
            subcategories[sub.name] = {
              value: sub.id,
              label: sub.name,
            };
          }
        });
      });
      console.log('subcategories', subcategories);
      return map(subcategories, categories => categories);
    },
    resetData(name, value) {
      this.$set(this, name, value);
    },
    subcategoriesProducts() {
      return this.optionsSubCategory.length
        ? filter(this.products, item => {
            let i = 0;
            let status = false;
            while (i < item.subcategories.length) {
              const index = this.optionsSubCategory.indexOf(
                item.subcategories[i].id
              );
              if (index >= 0) {
                status = true;
                break;
              }
              i++;
            }
            return status;
          })
        : this.products;
    },
  },
  async beforeMount() {
    const products = await this.$store.dispatch(
      'fetchProductCompanies',
      this.$route.params.category
    );
    this.products = this.createProductsData(products.data.data.companies);
    this.optionsSubCategories = this.parseSubcategories(this.products);
    this.maxDistance = maxBy(
      this.products,
      'distanceWithoutKM'
    ).distanceWithoutKM;
    console.log('companies', this.products);
  },
};
</script>

<style lang="scss" scoped>
.products-categories-category {
  .filters {
    align-items: center;
    color: #2b2b2b;
    display: flex;
    height: 44px;
    margin-bottom: 23px;
    .selection-wrapper {
      min-width: 200px;
    }
    .others-filters {
      align-items: center;
      display: flex;
      overflow: auto;
      padding-left: 75px;
      width: calc(100% - 200px);
      > div {
        margin-right: 75px;
      }
    }
    .selection-wrapper,
    .others-filters > div {
      font-weight: 500;
    }
    .range-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .range-title {
        font-size: 16px;
      }
      .range-value {
        align-items: center;
        background-color: #f4f4f4;
        border-radius: 10px;
        display: flex;
        height: 44px;
        justify-content: center;
        padding: 0 5px;
        width: 52px;
      }
    }
  }
}
</style>
