<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25974 0.932261C7.32925 0.69132 7.67064 0.69132 7.74015 0.932261L9.13181 5.75635C9.16268 5.86336 9.26063 5.93705 9.37201 5.93705L13.9327 5.93725C14.1676 5.93726 14.2728 6.23192 14.091 6.38071L10.3522 9.44086C10.2749 9.50419 10.2427 9.60753 10.2704 9.7036L11.686 14.612C11.754 14.8477 11.4773 15.0301 11.2875 14.8748L7.65828 11.9048C7.56618 11.8294 7.43372 11.8294 7.34162 11.9048L3.71244 14.8748C3.52263 15.0301 3.24593 14.8477 3.3139 14.612L4.72952 9.7036C4.75723 9.60753 4.72503 9.50419 4.64765 9.44086L0.908845 6.38071C0.727056 6.23192 0.832261 5.93726 1.06718 5.93725L5.62789 5.93705C5.73927 5.93705 5.83721 5.86336 5.86808 5.75635L7.25974 0.932261Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#999999',
    },
  },
};
</script>

<style lang="scss" scoped></style>
