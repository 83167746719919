<template>
  <div class="star-rating">
    <span
      v-for="star in stars"
      :key="star"
      @click="emitValue ? $emit('starRatingValue', star) : null"
    >
      <StarIcon :color="activeStars >= star ? activeColor : defaultColor" />
    </span>
  </div>
</template>

<script>
import StarIcon from '../Svgs/StarIcon';
export default {
  components: { StarIcon },
  props: {
    stars: {
      type: Number,
      required: true,
      validator: num => num >= 0 && num <= 5,
    },
    activeStars: {
      type: Number,
      required: true,
      validator: num => num >= 0 && num <= 5,
    },
    defaultColor: {
      type: String,
      default: '#999999',
    },
    activeColor: {
      type: String,
      default: '#E58413',
    },
    emitValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.star-rating {
  display: flex;
}
</style>
